export function convertGMTtoLocal(gmtDateString) {
  // Create a new Date object from the GMT string
  const date = new Date(gmtDateString);

  // Get local month, day, and year
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add 1 because months are 0-based
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();

  // Return formatted date string
  return `${month}/${day}/${year}`;
}
