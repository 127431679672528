<template>
  <div
    class="custom-file-input"
    id="drop-zone"
    @click="triggerFileInput"
    @dragover.prevent="handleDragOver"
    @dragleave.prevent="handleDragLeave"
    @drop.prevent="handleDrop"
  >
    <input
      ref="fileInput"
      type="file"
      class="file-input"
      accept="application/pdf"
      @change="handleFileSelect"
      multiple
    />
    <span
      class="material-symbols-outlined upload-button"
      @click="triggerFileInput"
    >
      add_circle
    </span>
    <p class="drag-drop-text">Drag and drop or click to upload a pdf</p>
  </div>
</template>

<script>
export default {
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileSelect(event) {
      const files = Array.from(event.target.files);
      this.$emit("files-selected", files);
    },
    handleDragOver(event) {
      event.target.classList.add("drag-over");
    },
    handleDragLeave(event) {
      event.target.classList.remove("drag-over");
    },
    handleDrop(event) {
      event.target.classList.remove("drag-over");
      const files = Array.from(event.dataTransfer.files);
      if (files.length > 0) {
        this.$emit("files-selected", files);
      }
    },
  },
};
</script>
<style scoped>
.custom-file-input {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1vw;
  height: 55vh;
  border: 0.1vw dashed darkgrey;
  border-radius: 1rem;
  text-align: center;
  transition: background-color 0.3s ease;
}

.file-input {
  display: none;
}

.upload-button {
  color: var(--primary);
  cursor: pointer;
  font-size: 2vw;
  transition: background-color 0.3s ease;
}

.upload-button:hover {
  color: var(--classi2);
  transition: ease all 0.3s;
}

.upload-button:active {
  color: var(--classi2);
}

.custom-file-input.drag-over {
  background-color: #e3f2fd;
  border-color: #2196f3;
}

.drag-drop-text {
  margin-top: 1vw;
  font-size: 1vw;
}
</style>
