<template>
  <div class="step-container">
    <div class="flex-container">
      <div v-if="Object.keys(submissions).length > 0" class="submissions-list">
        <div class="submissions-list-heading">
          Submissions
          <span
            v-if="gradingComplete"
            class="material-symbols-outlined"
            @click="downloadAll"
            title="Download all"
            >download</span
          >
        </div>
        <div
          v-for="(submission, fileName) in submissions"
          :key="fileName"
          class="submission-item"
          :class="{ active: currentSubmissionName === fileName }"
          @click="currentSubmissionName = fileName"
        >
          {{ fileName }}
          <span
            v-if="fileName in submissions && submissions[fileName].graded"
            class="material-symbols-outlined"
            @click="downloadPdf(fileName)"
            >download</span
          >
          <span
            v-else
            class="material-symbols-outlined"
            @click="downloadPdf(fileName)"
            ><LoadingSpinner class="mini-spinner"
          /></span>
        </div>
        <div
          class="overview-tab"
          :class="{ active: currentSubmissionName === 'overview' }"
          @click="
            console.log(overviewStats);
            currentSubmissionName = 'overview';
          "
        >
          Overview
        </div>
        <div class="add-submission-button" @click="$refs.fileInput.click()">
          <span class="material-symbols-outlined">add_circle</span>
        </div>
        <input
          ref="fileInput"
          type="file"
          accept=".pdf"
          multiple
          class="hidden-file-input"
          @change="uploadExtraSubmissions"
        />
      </div>
      <div v-if="currentSubmissionName === 'overview'" class="graded-container">
        <GradingOverview :overview-stats="overviewStats" />
      </div>
      <div
        v-if="
          !(currentSubmissionName in gradedQuestions) &&
          currentSubmissionName !== 'overview'
        "
        style="width: 100%"
      >
        <LoadingSpinner style="margin: 30vh auto" />
      </div>
      <div
        class="graded-container"
        v-for="(submission, fileName) in submissions"
        :key="fileName"
        v-show="currentSubmissionName === fileName"
      >
        <div
          class="graded-total"
          v-if="
            gradedQuestions[fileName] && 'grading' in gradedQuestions[fileName]
          "
        >
          {{ gradedQuestions[fileName]["grading"].obtained.toFixed(2) }} /
          {{ gradedQuestions[fileName]["grading"].possible.toFixed(2) }}
        </div>
        <div
          v-for="(question, index) in gradedQuestions[fileName]"
          :key="index"
          class="graded-question"
        >
          <div class="question-info" v-if="index !== 'grading'">
            <div class="question-header">
              <div class="section-break"></div>
              <p class="graded-question-section">
                Question {{ question.question_number }}
              </p>
            </div>
            <TeacherTiptap
              class="wysiwyg"
              v-model:content="question.question"
              :editable="false"
            />
            <div class="question-header">
              <div class="section-break"></div>
              <p class="graded-question-section">Response</p>
              <div class="show-answer-icon">
                <span
                  class="material-symbols-outlined"
                  @mousedown="
                    () => {
                      showAnswerMode = question.question_number;
                    }
                  "
                  @mouseup="
                    () => {
                      showAnswerMode = -1;
                    }
                  "
                  @mouseleave="
                    () => {
                      showAnswerMode = -1;
                    }
                  "
                >
                  visibility</span
                >
              </div>
            </div>
            <TeacherTiptap
              class="wysiwyg"
              v-if="showAnswerMode === question.question_number"
              v-model:content="question.answer"
              :editable="false"
            />
            <TeacherTiptap
              class="wysiwyg"
              v-else
              v-model:content="question.response"
              :editable="false"
            />
          </div>

          <div class="question-feedback" v-if="index !== 'grading'">
            <div
              v-if="question.points_obtained === -1"
              class="spinner-container"
            >
              <LoadingSpinner class="feedback-spinner" />
              <p class="loading-text">Grading in progress</p>
            </div>
            <div v-else>
              <div class="question-grade">
                <input
                  v-model.number="question.points_obtained"
                  type="number"
                  :min="0"
                  :max="question.points_possible"
                  step="0.25"
                  @change="updateResponse(fileName, question)"
                />
                / {{ question.points_possible }}
              </div>
              <div class="edit-controls">
                <div
                  v-if="feedbackEditMode === question.question_number"
                  class="edit-option"
                  @click="cancelFeedback(fileName, question.question_number)"
                >
                  Cancel
                  <span class="material-symbols-outlined">close</span>
                </div>
                <div
                  v-if="feedbackEditMode === question.question_number"
                  class="edit-option"
                  @click="saveFeedback(fileName, question.question_number)"
                >
                  Save
                  <span class="material-symbols-outlined">check</span>
                </div>
                <div v-else>
                  <span
                    class="material-symbols-outlined"
                    @click="feedbackEditMode = question.question_number"
                    >edit</span
                  >
                </div>
              </div>
              <TeacherTiptap
                class="wysiwyg"
                v-model:content="question.feedback"
                :editable="feedbackEditMode === question.question_number"
                whiteMode="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/utils/axiosInstance";
import TeacherTiptap from "@/components/TeacherTiptap.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import GradingOverview from "@/components/GradingOverview.vue";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { generatePdf, generatePdfBlob } from "@/utils/pdfUtils";
import html2pdf from "html2pdf.js";
import {
  getMaxScoreStudents,
  getMinScoreStudents,
  getMeanScore,
  getMedianScore,
  getQuestionCorrectFrequency,
} from "@/utils/gradingUtils";


export default {
  name: "GradingStep",
  components: {
    TeacherTiptap,
    LoadingSpinner,
    GradingOverview,
  },
  props: [
    "markingSchemeId",
    "gradedQuestions",
    "submissions",
    "gradingComplete",
  ],
  data() {
    return {
      submissionLoading: false,
      submissionSuccess: false,
      currentSubmissionName: "",
      feedbackEditMode: -1,
      showAnswerMode: -1,
    };
  },
  mounted() {
    this.currentSubmissionName = Object.keys(this.submissions)[0];
  },
  computed: {
    currentSubmission() {
      return this.submissions[this.currentSubmissionName] || null;
    },
    overviewStats() {
      var submissions = Object.keys(this.submissions).length;
      var graded = Object.values(this.submissions).filter(
        (item) => item.graded === true
      ).length;

      return {
        submissions: submissions,
        graded: graded,
        min: getMinScoreStudents(this.gradedQuestions),
        max: getMaxScoreStudents(this.gradedQuestions),
        mean: getMeanScore(this.gradedQuestions),
        median: getMedianScore(this.gradedQuestions),
        questionCorrectFrequency: getQuestionCorrectFrequency(
          this.gradedQuestions
        ),
      };
    },
  },
  methods: {
    downloadPdf(fileName) {
      generatePdf(fileName, this.gradedQuestions);
    },
    async downloadAll() {
      const zip = new JSZip();
      for (const fileName in this.gradedQuestions) {
        const pdfBlob = await generatePdfBlob(fileName, this.gradedQuestions);
        zip.file(`${fileName}_graded.pdf`, pdfBlob);
      }
      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, "graded_submissions.zip");
    },
    loadSubmissions(files) {
      for (const file of files) {
        const isPDF = file.type === "application/pdf";
        const url = URL.createObjectURL(file);
        const filename = file.name.replace(".pdf", "");
        console.log(filename);
        this.submissions[filename] = {
          file,
          url,
          isPDF,
          loading: false,
          success: false,
        };
      }
      if (!this.currentSubmissionName) {
        this.currentSubmissionName = Object.keys(this.submissions)[0];
      }
    },
    async uploadSubmissions() {
      this.submissionLoading = true;
      try {
        const formData = new FormData();

        for (const fileName in this.submissions) {
          if (!this.submissions[fileName].graded) {
            formData.append(
              "files[]",
              this.submissions[fileName].file,
              fileName
            );
          }
        }
        formData.append("marking_scheme_id", this.markingSchemeId);

        const response = await axiosInstance.post(
          "/grader/process-submission",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        const data = response.data;
        for (const fileName in data) {
          this.gradedQuestions[fileName] = this.gradedQuestions[fileName] || {};

          this.gradedQuestions[fileName]["grading"] = {
            obtained: 0,
            possible: 0,
          };

          for (const question of data[fileName]) {
            question["response_id"] = -1;
            question["points_obtained"] = -1;
            question["points_possible"] = -1;
            question["feedback"] = "";
            question["original_feedback"] = "";
            this.gradedQuestions[fileName][question["question_number"]] =
              question;
          }
        }

        this["submissionLoading"] = false;
        this["submissionSuccess"] = true;

        setTimeout(() => {
          this["submissionSuccess"] = false;
        }, 1000);
      } catch (error) {
        console.error(`Error processing submissions:`, error);
        this["submissionLoading"] = false;
      }
    },
    async uploadExtraSubmissions(event) {
      const files = event.target.files;
      console.log(files);
      if (files.length > 0) {
        this.loadSubmissions(files);
        console.log(this.submissions);
        await this.uploadSubmissions();
        this.$emit("gradeSubmissions");
      }
    },
    calculateTotalPoints(fileName) {
      this.gradedQuestions[fileName]["grading"] = {
        possible: 0,
        obtained: 0,
      };
      for (const question in this.gradedQuestions[fileName]) {
        if (question !== "grading") {
          this.gradedQuestions[fileName]["grading"].possible += parseFloat(
            this.gradedQuestions[fileName][question].points_possible
          );
          this.gradedQuestions[fileName]["grading"].obtained += parseFloat(
            this.gradedQuestions[fileName][question].points_obtained
          );
        }
      }
    },
    async updateResponse(fileName, question) {
      this.calculateTotalPoints(fileName);
      try {
        const response = await axiosInstance.post("/grader/update-response", {
          response_id: question.response_id,
          points_obtained: question.points_obtained,
          feedback: question.feedback,
        });
        if (response.status === 200) {
          console.log("Response updated successfully");
        } else {
          console.error("Failed to update response");
        }
      } catch (error) {
        console.error("Error updating response:", error);
      }
    },
    saveFeedback(fileName, question_number) {
      this.feedbackEditMode = -1;
      this.gradedQuestions[fileName][question_number].original_feedback =
        this.gradedQuestions[fileName][question_number].feedback;
      this.updateResponse(
        fileName,
        this.gradedQuestions[fileName][question_number]
      );
    },
    cancelFeedback(fileName, question_number) {
      this.feedbackEditMode = -1;
      this.gradedQuestions[fileName][question_number].feedback =
        this.gradedQuestions[fileName][question_number].original_feedback;
    },
  },
};
</script>

<style scoped>
step-container {
  width: 33.33%;
  padding: 2vw;
  overflow-y: auto;
}

.flex-container {
  display: flex;
  overflow-x: hidden;
}

.submissions-list {
  width: 15vw;
  height: 80vh;
  border-right: 0.1rem solid var(--classi2);
  margin-right: 2vw;
  overflow-y: auto;
}

.submissions-list-heading {
  font-size: 1.4vw;
  padding: 1vw;
  border-bottom: 0.1rem solid var(--classi2);
}

.submission-item {
  word-break: break-all;
  padding: 1vw;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.submission-item:hover {
  background-color: var(--classi2);
  color: white;
  transition: ease all 0.3s;
}

.submission-item.active {
  background-color: var(--classi2);
  color: white;
}

.mini-spinner {
  width: 1vw;
  height: 1vw;
}

.overview-tab.active {
  background-color: var(--classi2);
  color: white;
}

.overview-tab:hover {
  background-color: var(--classi2);
  color: white;
}

.hidden-file-input {
  display: none;
}

.add-submission-button,
.overview-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1vw;
  cursor: pointer;
  border-top: 0.1rem solid var(--classi2);
  transition: background-color 0.3s ease;
}

.add-submission-button:hover {
  color: var(--classi2);
}

.add-submission-button .material-symbols-outlined {
  margin-right: 0.5vw;
}

.graded-container {
  flex: 1;
  height: 80vh;
  overflow-y: auto;
}

.graded-total {
  font-size: 2vw;
  padding: 2vw;
  color: var(--classi2);
}

.graded-question {
  display: flex;
  margin-bottom: 2vw;
  border: 0.1rem solid var(--classi2);
  border-radius: 2rem;
  box-shadow: var(--box-shadow);
  overflow: hidden;
}

.question-info {
  flex: 3;
  padding: 1.5vw 1vw;
  font-size: 1vw;
}

.question-feedback {
  flex: 1;
  background-color: var(--classi2);
  color: white;
  padding: 1.5vw 1vw;
  font-size: 1vw;
}

.question-header {
  display: flex;
  align-items: center;
  margin-bottom: 1vw;
}

.section-break {
  width: 5%;
  height: 2px;
  background-color: var(--classi2);
  margin-right: 1vw;
}

.graded-question-section {
  font-size: 1.3vw;
  color: var(--classi2);
}

.edit-controls {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.edit-option {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 1vw;
  border: 0.1vw solid var(--classi2);
  border-radius: 1rem;
  padding: 0.3vw;
  transition: all ease 0.3s;
}

.edit-option:hover {
  opacity: 0.6;
}

.material-symbols-outlined {
  cursor: pointer;
  font-size: 1.4vw;
  margin: 0 0.2vw;
  transition: all ease 0.3s;
}

.material-symbols-outlined:hover {
  opacity: 0.6;
}

.question-grade {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2vw;
  margin: 1vw 0;
}

input[type="number"] {
  width: 5vw;
  font-size: 2vw;
  text-align: center;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 0.5rem;
}

.wysiwyg {
  margin-top: 1vh;
}

.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading-text {
  margin-top: 2vh;
}

.show-answer-icon {
  margin-left: auto;
  cursor: pointer;
}

.show-answer-icon .material-symbols-outlined {
  font-size: 1.2vw;
  color: var(--classi2);
}
</style>
