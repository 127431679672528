<template>
  <div class="document-container">
    <div class="file-wrapper">
      <div class="file-overlay" v-if="loading || success">
        <div v-if="loading" class="loading-container">
          <LoadingSpinner />
          <p class="loading-text">Processing {{ fileType }}</p>
        </div>
        <div v-if="success" class="success-icon">
          <span class="material-symbols-outlined">check</span>
        </div>
      </div>
      <iframe
        v-if="isPDF"
        :src="fileUrl"
        type="application/pdf"
        class="pdf-display"
      ></iframe>
      <img v-else :src="fileUrl" alt="Uploaded file" class="image-display" />
    </div>
    <div class="display-buttons">
      <div
        :class="['remove-button', { disabled: loading }]"
        @click="!loading && $emit('remove')"
      >
        <span class="material-symbols-outlined">delete</span>
      </div>
      <div
        :class="['continue-button', { disabled: loading }]"
        @click="!loading && $emit('continue')"
      >
        <span class="material-symbols-outlined">arrow_forward</span>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  components: {
    LoadingSpinner,
  },
  props: ["fileUrl", "isPDF", "loading", "success", "fileType"],
};
</script>

<style scoped>
.document-container {
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.file-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.pdf-display {
  height: 100%;
  width: 100%;
}

.image-display {
  max-height: 65vh;
  max-width: 100%;
  object-fit: contain;
}

.display-buttons {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  font-size: 1.3vw;
  margin-top: 5vh;
}

.remove-button,
.continue-button {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 1vw;
  color: var(--classi2);
  border: 0.1rem solid var(--classi2);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.remove-button:hover:not(.disabled),
.continue-button:hover:not(.disabled) {
  background-color: var(--classi2);
  color: white;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.file-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-text {
  margin-top: 1rem;
  font-size: 1rem;
  color: white;
}

.success-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #4caf50;
}

.success-icon .material-symbols-outlined {
  font-size: 40px;
  color: white;
}
</style>
