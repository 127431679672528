// Import statements
import html2pdf from "html2pdf.js";
import DOMPurify from "dompurify";

// Utility function to sanitize HTML using DOMPurify
export function sanitizeHTML(str) {
  return DOMPurify.sanitize(str);
}

// Utility function to generate PDF
export async function generatePdf(fileName, gradedQuestions) {
  const contentDiv = document.createElement("div");
  contentDiv.style.lineHeight = "2";
  contentDiv.style.fontFamily = "'Noto Sans', sans-serif";

  const titleDiv = document.createElement("div");
  titleDiv.innerHTML = `
    <h1>Graded Submission: ${sanitizeHTML(fileName)}</h1>
    <h2>Total Score: ${gradedQuestions[fileName]["grading"].obtained.toFixed(2)} / ${gradedQuestions[fileName]["grading"].possible.toFixed(2)}</h2>
  `;
  contentDiv.appendChild(titleDiv);

  const questions = gradedQuestions[fileName];
  for (const [questionNumber, question] of Object.entries(questions)) {
    if (questionNumber === 'grading') continue;

    const questionDiv = document.createElement("div");
    questionDiv.innerHTML = `
      <h3>Question ${sanitizeHTML(questionNumber)}</h3>
      <div class="question-content">${sanitizeHTML(question.question)}</div>
      <h4>Student Response:</h4>
      <div class="student-response">${sanitizeHTML(question.response)}</div>
      <h4>Correct Answer:</h4>
      <div class="correct-answer">${sanitizeHTML(question.answer)}</div>
      <h4>Grade: ${question.points_obtained} / ${question.points_possible}</h4>
      <h4>Feedback:</h4>
      <div class="feedback">${sanitizeHTML(question.feedback)}</div>
      <hr><br/>
    `;
    contentDiv.appendChild(questionDiv);
  }

  document.body.appendChild(contentDiv);
  await document.fonts.ready;

  if (window.MathJax) await MathJax.typesetPromise([contentDiv]);

  // Remove any unwanted elements
  contentDiv.querySelectorAll("script").forEach((el) => el.remove());
  contentDiv.querySelectorAll('[style*="display: none"]').forEach((el) => el.remove());

  const opt = {
    margin: [20, 20, 20, 20],
    filename: `${fileName}_graded.pdf`,
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2, useCORS: true },
    jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  };

  try {
    await html2pdf().from(contentDiv).set(opt).save();
  } catch (error) {
    console.error("Error generating PDF:", error);
  } finally {
    document.body.removeChild(contentDiv);
  }
}

// Utility function to generate PDF as a Blob
export async function generatePdfBlob(fileName, gradedQuestions) {
  const contentDiv = document.createElement("div");
  contentDiv.style.lineHeight = "2";
  contentDiv.style.fontFamily = "'Noto Sans', sans-serif";

  const titleDiv = document.createElement("div");
  titleDiv.innerHTML = `
    <h1>Graded Submission: ${sanitizeHTML(fileName)}</h1>
    <h2>Total Score: ${gradedQuestions[fileName]["grading"].obtained.toFixed(2)} / ${gradedQuestions[fileName]["grading"].possible.toFixed(2)}</h2>
  `;
  contentDiv.appendChild(titleDiv);

  const questions = gradedQuestions[fileName];
  for (const [questionNumber, question] of Object.entries(questions)) {
    if (questionNumber === 'grading') continue;

    const questionDiv = document.createElement("div");
    questionDiv.innerHTML = `
      <h3>Question ${sanitizeHTML(questionNumber)}</h3>
      <div class="question-content">${sanitizeHTML(question.question)}</div>
      <h4>Student Response:</h4>
      <div class="student-response">${sanitizeHTML(question.response)}</div>
      <h4>Correct Answer:</h4>
      <div class="correct-answer">${sanitizeHTML(question.answer)}</div>
      <h4>Grade: ${question.points_obtained} / ${question.points_possible}</h4>
      <h4>Feedback:</h4>
      <div class="feedback">${sanitizeHTML(question.feedback)}</div>
      <hr><br/>
    `;
    contentDiv.appendChild(questionDiv);
  }

  document.body.appendChild(contentDiv);
  await document.fonts.ready;

  if (window.MathJax) await MathJax.typesetPromise([contentDiv]);

  // Remove any unwanted elements
  contentDiv.querySelectorAll("script").forEach((el) => el.remove());
  contentDiv.querySelectorAll('[style*="display: none"]').forEach((el) => el.remove());

  const opt = {
    margin: [20, 20, 20, 20],
    filename: `${fileName}_graded.pdf`,
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2, useCORS: true },
    jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  };

  try {
    // Generate the PDF as a Blob and return it
    const pdfBlob = await html2pdf().from(contentDiv).set(opt).output("blob");
    return pdfBlob;
  } catch (error) {
    console.error("Error generating PDF Blob:", error);
  } finally {
    document.body.removeChild(contentDiv);
  }
}