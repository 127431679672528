import { defineStore } from "pinia";
import axiosInstance from "@/utils/axiosInstance";

export const useInstructorStore = defineStore("instructorData", {
  state: () => ({
    isAuthenticated: false,
    email: "",
    firstName: "",
    title: "",
  }),
  actions: {
    async checkAuth() {
      try {
        await axiosInstance.get("/auth/instructor-protected");
        this.isAuthenticated = true;
      } catch (error) {
        this.isAuthenticated = false;
      }
      return this.isAuthenticated;
    },
    async logout() {
      this.isAuthenticated = false;
      localStorage.removeItem("instructorData");      
      await axiosInstance.post("/auth/logout");
    },
    async getInstructorData() {
      try {
        const studentResponse = await axiosInstance.get(
          "/instructors/get-instructor"
        );
        const data = studentResponse.data;
        this.email = data.email;
        this.firstName = data.firstName;
        this.title = data.title;
      } catch (error) {
        console.log("User not found", error);
      }
    },
  },
  persist: {
    enabled: true,
    strategies: [{ storage: localStorage }],
  },
});
