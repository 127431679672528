<template>
  <div :class="['main-container', { collapsed: isCollapsed }]">
    <Sidebar @toggle="handleToggle" :collapsed="isCollapsed" />
    <div :class="['content-container', { collapsed: isCollapsed }]">
      <StudentHeader title="Assignments" />
      <LoadingSpinner class="spinner" v-if="loading" />
      <div v-if="!loading">hey there</div>
      <create-assignment-popup
        @create-assignment="handleNewAssignment"
      ></create-assignment-popup>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/components/InstructorSidebar.vue";
import StudentHeader from "@/components/StudentHeader.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import CreateAssignmentPopup from "@/components/CreateAssignmentPopup.vue";

export default {
  name: "InstructorAssignments",
  components: {
    Sidebar,
    StudentHeader,
    LoadingSpinner,
    CreateAssignmentPopup,
  },
  data() {
    return {
      isCollapsed: false,
      loading: false,
    };
  },
};
</script>
<style scoped>
.main-container {
  display: flex;
  transition: all 0.3s ease-in-out;
  font-family: var(--main-font);
}

.main-container.collapsed .content-container {
  margin-left: 5vw;
}

.main-container:not(.collapsed) .content-container {
  margin-left: 18vw;
}

.content-container {
  flex: 1;
  transition: margin-left 0.3s ease-in-out;
  color: var(--primary);
  font-size: 1.2rem;
}

.spinner {
  margin: 20vh auto;
}
</style>
