// Function to get the max score and students who got it
export function getMaxScoreStudents(grades) {
  let maxScore = -Infinity;
  let maxScoreStudents = [];

  for (const [student, data] of Object.entries(grades)) {
    const obtained = data.grading.obtained;
    if (obtained > maxScore) {
      maxScore = obtained;
      maxScoreStudents = [student];
    } else if (obtained === maxScore) {
      maxScoreStudents.push(student);
    }
  }

  return { maxScore, maxScoreStudents };
}

// Function to get the min score and students who got it
export function getMinScoreStudents(grades) {
  let minScore = Infinity;
  let minScoreStudents = [];

  for (const [student, data] of Object.entries(grades)) {
    const obtained = data.grading.obtained;
    if (obtained < minScore) {
      minScore = obtained;
      minScoreStudents = [student];
    } else if (obtained === minScore) {
      minScoreStudents.push(student);
    }
  }

  return { minScore, minScoreStudents };
}

// Function to calculate the mean score
export function getMeanScore(grades) {
  const totalScores = Object.values(grades).reduce(
    (sum, data) => sum + data.grading.obtained,
    0
  );
  return totalScores / Object.keys(grades).length;
}

// Function to calculate the median score
export function getMedianScore(grades) {
  const scores = Object.values(grades)
    .map((data) => data.grading.obtained)
    .sort((a, b) => a - b);
  const mid = Math.floor(scores.length / 2);
  return scores.length % 2 !== 0
    ? scores[mid]
    : (scores[mid - 1] + scores[mid]) / 2;
}

// Function to calculate how many students got full marks for each question
export function getQuestionCorrectFrequency(grades) {
  const questionStats = {};

  // Initialize counts for each question, even if no student got full marks
  for (const data of Object.values(grades)) {
    for (const [question, details] of Object.entries(data)) {
      if (question !== "grading") {
        if (!(question in questionStats)) {
          questionStats[question] = 0; // Initialize the count to 0
        }
      }
    }
  }

  // Update counts for students who got full marks in each question
  for (const data of Object.values(grades)) {
    for (const [question, details] of Object.entries(data)) {
      if (question !== "grading") {
        const obtained = parseFloat(details.points_obtained);
        const possible = parseFloat(details.points_possible);

        if (obtained === possible) {
          questionStats[question] += 1;
        }
      }
    }
  }

  return questionStats;
}
