<template>
  <nav :class="['sidebar', { collapsed: isCollapsed }]">
    <div class="top" @click="toggleSidebar">
      <span class="material-symbols-outlined hamburger"> menu </span>
      <router-link to="/dashboard" class="logo" v-if="!isCollapsed">
        <span class="classi1">cl</span>
        <span class="classi2">a</span>
        <span class="classi1">ss</span>
        <span class="classi2">i</span>
      </router-link>
    </div>
    <div v-if="!isCollapsed" class="nav-links">
      <router-link v-for="(item, index) in menuItems" :key="index" :to="item.route" class="nav-link"
        active-class="active-link">
        <span v-if="!isCollapsed">{{ item.title }}</span>
      </router-link>
    </div>
    <div v-if="!isCollapsed" style="width:100%; display: flex; justify-content: space-between; align-items: center">
      <div class="themeDiv">
        <!-- <ThemeSwitcher @themeChange="handleThemeChange" /> -->
      </div>
      <div class="logout-button" title="logout" @click="logout">
        <span style="font-size: 2rem;" class="material-symbols-outlined"> logout </span>
      </div>
    </div>
  </nav>
</template>

<script>
import ThemeSwitcher from "./ThemeSwitcher.vue";
import { useInstructorStore } from "@/store/instructorData";

export default {
  data() {
    return {
      userAvatar: require("@/assets/JohnSnow.jpg"),
      menuItems: [
        // { title: "Dashboard", route: "/instructor/dashboard" },
        // { title: "Assignments", route: "/instructor/assignments" },
        { title: "Grader", route: "/instructor/grader" },
      ],
    };
  },

  components: {
    ThemeSwitcher,
  },
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.instructorStore = useInstructorStore();
  },
  methods: {
    toggleSidebar() {
      this.$emit("toggle", this.isCollapsed);
    },
    handleThemeChange(currentTheme) {
      this.$emit("themeChange", currentTheme);
    },
    async logout() {
      await this.instructorStore.logout();
      this.$router.push("/login");
    },
  },
  computed: {
    isCollapsed() {
      return this.collapsed;
    },
  },
};
</script>

<style scoped>
.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--sidebar);
  height: 100%;
  width: 18vw;
  box-shadow: var(--box-shadow);
  font-family: var(--accent-font);
  transition: width 0.3s;
}

.sidebar.collapsed {
  width: 6vw;
}

.top {
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
}

.hamburger {
  margin: 2vh 2vw 2vh 1vw;
  font-size: 2rem;
  cursor: pointer;
  color: var(--primary);
  transform: rotate(0deg);
  transition: all ease 0.3s;
}

.hamburger:hover {
  color: var(--classi2);
  transform: rotate(360deg);
  transition: all ease 0.3s;
}

.logo {
  flex: 1;
  padding: 2vh 2vw 2vh 0vw;
  font-size: 2.5vw;
  font-weight: 100;
  letter-spacing: 1vh;
  text-decoration: none;
  white-space: nowrap;
}

.classi1 {
  color: var(--classi1);
  text-transform: none;
}

.classi2 {
  color: var(--classi2);
  text-transform: none;
}

.nav-links {
  flex: 9;
  /* padding: 1rem; */
}

.nav-link {
  display: block;
  text-decoration: none;
  color: var(--primary);
  font-size: 0.9vw;
  transition: background-color 0.3s ease;
  padding: 4vh 3vw 4vh 6vw;
}

.nav-link:hover {
  background-color: var(--classi2);
  color: var(--secondary);
}

.active-link,
.exact-active-link {
  background-color: var(--primary);
  color: var(--secondary);
}

.themeDiv {
  flex: 1;
  justify-content: left;
  align-items: flex-end;
  padding: 2rem;
  display: flex;
}

.logout-button {
  margin-right: 1vw;
  padding: 0.6vw;
  border-radius: 5rem;  
  cursor: pointer;
}

.logout-button:hover {
  color: var(--classi2);
}
</style>
