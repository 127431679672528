<template>
  <div class="overview-wrapper">
    <!-- Grading Overview -->
    <div class="overview-container">
      <!-- Header Section -->
      <div
        class="overview-header"
        @click="$emit(expanded ? 'collapse' : 'expand')"
      >
        <div class="stats-summary" :class="{ expanded: expanded }">
          <div class="stat-item" :class="{ expanded: expanded }">
            <p class="stat-label">Submissions</p>
            <p class="stat-value" :class="{ expanded: expanded }">
              {{ overviewStats.submissions }}
            </p>
          </div>
          <div class="stat-item" :class="{ expanded: expanded }">
            <p class="stat-label">Graded</p>
            <p class="stat-value" :class="{ expanded: expanded }">
              {{ overviewStats.graded }}
            </p>
          </div>
          <div class="stat-item" :class="{ expanded: expanded }">
            <p class="stat-label">Average</p>
            <p class="stat-value" :class="{ expanded: expanded }">
              {{ overviewStats.mean.toFixed(2) }}
            </p>
          </div>
          <div class="stat-item" :class="{ expanded: expanded }">
            <p class="stat-label">Median</p>
            <p class="stat-value" :class="{ expanded: expanded }">
              {{ overviewStats.median.toFixed(2) }}
            </p>
          </div>
        </div>
        <div class="toggle-icon">
          <span class="material-symbols-outlined">
            {{ expanded ? "expand_less" : "expand_more" }}
          </span>
        </div>
      </div>

      <!-- Expanded Content -->
      <transition
        @before-enter="beforeEnter"
        @enter="enter"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
        @leave="leave"
        @after-leave="afterLeave"
      >
        <div v-if="expanded" class="overview-content">
          <!-- Detailed Stats -->
          <div class="stats-details">
            <div
              class="stat-card"
              v-for="(stat, index) in detailedStats"
              :key="index"
            >
              <div style="flex: 2">
                <p class="stat-label">{{ stat.label }}</p>
                <p class="stat-value">{{ stat.value }}</p>
              </div>
              <div
                style="
                  flex: 2;
                  margin-top: 0vh;
                  height: 8vh;
                  font-size: 0.9rem;
                  color: grey;
                "
              >
                Students
                <ul
                  style="
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    list-style-type: none;
                    overflow-y: auto;
                  "
                >
                  <li
                    v-for="student of stat.students"
                    style="color: var(--primary)"
                  >
                    {{ student }}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="graphics-container">
            <!-- Chart for Score Distribution -->
            <div class="chart-container">
              <BarChart
                :data="scoreDistributionData"
                :options="scoreChartOptions"
              />
            </div>
            
            <!-- Chart for Full Marks per Question -->
            <div class="chart-container">
              <BarChart
                :data="questionCorrectData"
                :options="questionChartOptions"
              />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "GradingOverview",
  props: ["overviewStats", "expanded"],
  computed: {
    detailedStats() {
      return [
        {
          label: "Highest Score",
          value: this.overviewStats.max.maxScore,
          students: this.overviewStats.max.maxScoreStudents,
        },
        {
          label: "Lowest Score",
          value: this.overviewStats.min.minScore,
          students: this.overviewStats.min.minScoreStudents,
        },
      ];
    },
    questionCorrectData() {
      const dataValues = Object.values(
        this.overviewStats.questionCorrectFrequency
      );
      return {
        labels: Object.keys(this.overviewStats.questionCorrectFrequency),
        datasets: [
          {
            label: "Students with Full Marks",
            backgroundColor: this.generateColors(dataValues.length),
            data: Object.values(this.overviewStats.questionCorrectFrequency),
            borderRadius: 4,
          },
        ],
      };
    },
    scoreDistributionData() {
      // Compute the distribution of scores
      const scoreCounts = {};
      this.overviewStats.scores.forEach((score) => {
        scoreCounts[score] = (scoreCounts[score] || 0) + 1;
      });

      const labels = Object.keys(scoreCounts).sort((a, b) => a - b);
      const data = labels.map((label) => scoreCounts[label]);

      return {
        labels,
        datasets: [
          {
            label: "Number of Students",
            backgroundColor: this.generateColors(data.length),
            data,
            borderRadius: 4,
          },
        ],
      };
    },
    questionChartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
          title: {
            display: true,
            text: "Students with full marks per question",
            color: "#333",
            font: { size: 18 },
            padding: { top: 20, bottom: 20 },
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                const count = tooltipItem.raw;
                return `${count} student${count !== 1 ? "s" : ""}`;
              },
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            max: this.overviewStats.submissions,
            title: {
              display: true,
              text: "Number of Students",
              font: { size: 14 },
            },
            grid: { color: "transparent", drawBorder: false },
            ticks: { stepSize: 1, color: "#666" },
          },
          x: {
            title: {
              display: true,
              text: "Question Number",
              font: { size: 14 },
            },
            grid: { display: false },
            ticks: { color: "#666" },
          },
        },
      };
    },
    scoreChartOptions() {
      return {
        // indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
          title: {
            display: true,
            text: "Score Distribution",
            color: "#333",
            font: { size: 18 },
            padding: { top: 20, bottom: 20 },
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                const count = tooltipItem.raw;
                return `${count} student${count !== 1 ? "s" : ""}`;
              },
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            max: this.overviewStats.submissions,
            title: {
              display: true,
              text: "Number of Students",
              font: { size: 14 },
            },
            grid: { color: "transparent", drawBorder: false },
            ticks: { stepSize: 1, color: "#666" },
          },
          x: {
            title: {
              display: true,
              text: "Score",
              font: { size: 14 },
            },
            grid: { display: false },
            ticks: { color: "#666" },
            barThickness: "flex",
          },
        },
      };
    },
  },
  components: {
    BarChart: Bar,
  },
  methods: {
    generateColors(count) {
      const baseColor = "#66b2b2";
      return Array(count)
        .fill()
        .map((_, i) => {
          // Darken the color for each subsequent bar
          const darkenAmount = i * (100 / count);
          return this.adjustColor(baseColor, -darkenAmount);
        });
    },
    adjustColor(color, amount) {
      const clamp = (val) => Math.min(Math.max(val, 0), 255);

      // Convert hex to RGB
      const hex = color.replace("#", "");
      const r = parseInt(hex.substr(0, 2), 16);
      const g = parseInt(hex.substr(2, 2), 16);
      const b = parseInt(hex.substr(4, 2), 16);

      // Adjust each component
      const newR = clamp(r + amount);
      const newG = clamp(g + amount);
      const newB = clamp(b + amount);

      // Convert back to hex
      const toHex = (n) => {
        const hex = n.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      };

      return `#${toHex(newR)}${toHex(newG)}${toHex(newB)}`;
    },
    beforeEnter(el) {
      el.style.height = "0";
    },
    enter(el) {
      el.style.height = el.scrollHeight + "px";
    },
    afterEnter(el) {
      el.style.height = "auto";
    },
    beforeLeave(el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave(el) {
      el.style.height = "0";
    },
    afterLeave(el) {
      el.style.height = "auto";
    },
  },
};
</script>

<style scoped>
/* Variables */
:root {
  --primary-color: #1976d2;
  --secondary-color: #424242;
  --text-color: #333;
  --background-color: #fff;
  --card-background: #f9f9f9;
}

/* Overview Wrapper */
.overview-wrapper {
  width: 100%;
}

/* Overview Container */
.overview-container {
  margin: 2vh 0;
  background-color: var(--accent3);
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Header Section */
.overview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vh 2vw;
  cursor: pointer;
}

.stats-summary {
  display: flex;
  gap: 2vw;
  justify-content: flex-start;
  transition: all 0.3s ease;
  width: 100%;
}

.stats-summary.expanded {
  justify-content: center;
}

.stat-item {
  text-align: center;
  transition: all 0.3s ease;
}

.stat-item.expanded {
  flex: 1;
  background-color: white;
  box-shadow: var(--box-shadow);
  padding: 2vh 2vw;
  margin: 0 2vw;
  border-radius: 1rem;
}

.stat-item.expanded:hover {
  transform: translateY(-5%);
}

.stat-label {
  font-size: 0.9rem;
  color: grey;
}

.stat-value {
  font-size: 1.2rem;
  color: var(--primary);
}

.stat-value.expanded {
  font-size: 2vw;
}

.toggle-icon .material-symbols-outlined {
  font-size: 2rem;
  color: var(--primary);
}

/* Content Section */
.overview-content {
  overflow: hidden;
  transition: height 0.3s ease;
}

/* Stats Details */
.stats-details {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2vw;
  padding: 2vh 2vw;
}

.stat-card {
  display: flex;
  background-color: white;
  box-shadow: var(--box-shadow);
  padding: 2vh 2vw;
  width: 30vw;
  border-radius: 1rem;
  box-sizing: border-box;
}

.stat-card .stat-label {
  font-size: 0.9rem;
  color: grey;
  margin-bottom: 1vh;
}

.stat-card .stat-value {
  font-size: 2rem;
  color: var(--primary-color);
}

.graphics-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

/* Chart Container */
.chart-container {
  width: 30vw;
  height: 40vh;
  margin-top: 4vh;
  padding: 0 2vw 2vh;
}

.chart-container canvas {
  max-height: 400px;
}
</style>
